import './menu.scss';
import { Link, useParams, useLocation} from 'react-router-dom'
import { useState, useEffect } from 'react'
import { connect, useDispatch } from "react-redux";
import { setMenuSelect, setStep, setDisplayUnderMenu } from '../../actions/action.js'
import excoLogo from '../../assets/img/exco/Logo-exco-retina-1.png'
import UnderMenu from './underMenu.js'

const Menu = ( {idUser, idQuestionnaire, allQuestions, idCorrespond, menuContent, menu, displayUnderMenu, needUnderMenu, displayReportMenu, themeSelectionActive} ) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const {menuId, pageId} = useParams()
  const [underMenu, setUnderMenu] = useState(allQuestions.questions[0])
  
  let findIdText = null;
  if (idCorrespond){
    findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire) 
  }
  
  let menuIndex = null
  let menuObj = null

  const findUnderMenu = (id) => {
    if(needUnderMenu){
      setUnderMenu(allQuestions.questions.find(obj => obj.category.id == id))
    if (menu && menu[0]){
      menuIndex = allQuestions.questions.findIndex(obj => obj.category.id == id)
      menuObj = allQuestions.questions.find(obj => obj.category.id == menu[menuIndex].id)
    } 
    }
  }

  useEffect(() => {
    findUnderMenu(menuId)
  }, [menuId])  
  
    return (
      <div className="menu">
        {!displayUnderMenu || !needUnderMenu ?
        <div className="menu__container">
        <div className="menu__ul">
          <Link  to={findIdText ? process.env.REACT_APP_FRONT_LINK+'/'+ findIdText.text : process.env.REACT_APP_FRONT_LINK+'/'+ idQuestionnaire}><img className='menu__logo'src={excoLogo} alt='logo' /></Link>
           {menuContent ? menuContent.map((m, mIndex) => {
            return(
                  <Link key={mIndex} 
                  className={menuId == m.category.id ? "menu__ul__li selected" : "menu__ul__li"} 
                  to={`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${m.category.id}/${m.pages[0].text.id}/0/${m.pages[0].lots[0].sousquestions[0].id}`}
                  onClick={() => {dispatch(setDisplayUnderMenu(true)); dispatch(setStep(0))}}>
                    <div>{m.category.title}</div>
                  </Link>
                  )})
              : null }
             {displayReportMenu ? <Link to={findIdText ? process.env.REACT_APP_FRONT_LINK+'/rapport/'+findIdText.text+'/'+idUser : '/rapport/'+idQuestionnaire+'/'+idUser} className={location.pathname.includes('rapport') ? "menu__ul__li selected" : "menu__ul__li"}>Rapport</Link> : null}
             {themeSelectionActive ? <Link to={findIdText ? process.env.REACT_APP_FRONT_LINK+'/menu/'+findIdText.text +'/'+idUser : '/menu/'+idQuestionnaire+'/'+idUser} className={location.pathname.includes('menu') ? "menu__ul__li selected" : "menu__ul__li"}> Modifier vos choix de thèmes </Link> : null}
             
        </div>
        </div>
        : null}
        {needUnderMenu ? displayUnderMenu ? findIdText ? <UnderMenu idUser={idUser} idQuestionnaire={findIdText.text} allQuestions={allQuestions} menuContent={underMenu} /> : <UnderMenu idUser={idUser} idQuestionnaire={idQuestionnaire} allQuestions={allQuestions} menuContent={underMenu} sousmenu={true} /> : null : null}
      </div>
    );
  };
  
  const mapStateToProps = (state) => ({
    menu: state.menu, 
    menuSelect: state.menuSelect,
    allQuestions: state.allQuestions,
    languageSelected: state.languageSelected,
    needRapport: state.needRapport, 
    idUser: state.idUser,
    idQuestionnaire: state.idQuestionnaire,
    idCorrespond: state.idCorrespond,
    clickedMenu: state.clickedMenu,
    displayUnderMenu: state.displayUnderMenu,
    needUnderMenu: state.needUnderMenu,
    displayReportMenu: state.displayReportMenu,
    themeSelectionActive: state.themeSelectionActive,

  });
  const mapDispatchToProps = dispatch => ({
    setMenuSelect: (payload) => dispatch(setMenuSelect(payload)),
    setStep: (payload) => dispatch(setStep(payload)),
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(Menu);
